import React from 'react';
import './index.scss';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import Restaurant from '../../../assets/images/marker-brown1.png';
import Delivery from '../../../assets/images/marker.png';
import LOGO from '../../../assets/images/logo.png';
import { getOutlet } from '../../burger-king-lib/redux/address/actions';
import { connect } from 'react-redux';
import LocationDelivery from '../../../assets/images/locationDelivery.svg';
import LocationDinein from '../../../assets/images/locationDinein.svg';
import ToolTip from '../tooltip';
import { splitAddress } from '../../utils/services';

class GoogleMapSearch extends React.Component {
  constructor(props) {
    super(props);
    const restaurantDetail = JSON.parse(
      window.sessionStorage.getItem('RESTAURANT_DETAIL')
    );
    const theme = SessionStorage.get(LocalSessionKey.ORDER_TYPE);
    const deliveryAddress = JSON.parse(
      window.sessionStorage.getItem('DELIVERY_ADDRESS')
    );

    this.state = {
      map: null,
      markers: [],
      currentLocationMarker: null,
      theme: theme,
      restaurantDetail: restaurantDetail,
      deliveryAddress: deliveryAddress,
      latlng: this.getInitialLocation(restaurantDetail, theme, deliveryAddress),
      zoomLevel: LocalStorage.get('mapZoomLevel') || 12,
      isMapInitialized: false,
      isFirstLoad: true
    };
  }

  // Get initial location from storage or use restaurant detail if available

  componentDidMount() {
    if (!window.google) {
      console.error('Google Maps API not loaded');
      return;
    }

    const { theme, restaurantDetail } = this.state;

    // Initialize map with restaurant location for dinein
    if (theme === 'dinein' && restaurantDetail) {
      this.initializeMap();

      // After map is initialized, get outlet data
      setTimeout(() => {
        const payload = {
          lat: restaurantDetail.lat,
          long: restaurantDetail.long
        };
        this.props.getOutletData(payload);
      }, 100);
    } else {
      // For other cases, get current location first
      this.getCurrentLocationAndInitMap();
    }
  }

  getCurrentLocationAndInitMap = () => {
    // Always try to get current location first
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          // Update state with current location and then initialize map
          this.setState({ latlng: userLocation, isFirstLoad: true }, () => {
            console.log('Using current location for map:', userLocation);
            LocalStorage.set('USER_LOCATION', userLocation);
            this.initializeMap();
          });
        },
        error => {
          console.error('Error getting location:', error);
          // Initialize map with existing location if geolocation fails
          this.initializeMap();
        },
        { timeout: 15000, maximumAge: 600000 } // Add timeout options
      );
    } else {
      // Initialize map with existing location if geolocation not supported
      this.initializeMap();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.outletList !== this.props.outletList &&
      this.props.outletList
    ) {
      this.updateMarkers(this.props.outletList);
    }

    if (
      this.props.storeHorizontalVal &&
      (prevProps.storeHorizontalVal?.lat !==
        this.props.storeHorizontalVal?.lat ||
        prevProps.storeHorizontalVal?.lng !==
          this.props.storeHorizontalVal?.lng)
    ) {
      const isNearbyStores = SessionStorage.get(
        LocalSessionKey.NEARBY_BK_RESTUARANT
      );
      if (!isNearbyStores) {
        this.moveMarkerToStorePosition();
      }
    }
  }
  componentWillUnmount() {
    this.completeMapReset()
      .then(() => {
        // Clean up map listeners
        if (this.state.map) {
          window.google.maps.event.clearInstanceListeners(this.state.map);
        }
        this.setState({ map: null });
      })
      .catch(error => {
        console.error('Error cleaning up map:', error);
      });

    SessionStorage.remove(LocalSessionKey.NEARBY_BK_RESTUARANT);
  }

  getInitialLocation = (restaurantDetail, theme, deliveryAddress) => {
    // For dinein mode with restaurant details
    if (theme === 'dinein' && restaurantDetail?.lat && restaurantDetail?.long) {
      return {
        lat: parseFloat(restaurantDetail.lat),
        lng: parseFloat(restaurantDetail.long)
      };
    }

    // For delivery mode with delivery address
    if (
      theme === 'delivery' &&
      deliveryAddress?.latitude &&
      deliveryAddress?.longitude
    ) {
      return {
        lat: parseFloat(deliveryAddress.latitude),
        lng: parseFloat(deliveryAddress.longitude)
      };
    }

    // Fallback to stored location
    const storedLocation = LocalStorage.get('USER_LOCATION');
    if (storedLocation?.lat && storedLocation?.lng) {
      return {
        lat: parseFloat(storedLocation.lat),
        lng: parseFloat(storedLocation.lng)
      };
    }

    // Default location if nothing else is available
    return { lat: 0, lng: 0 };
  };

  moveMarkerToStorePosition = () => {
    if (!this.props.storeHorizontalVal) return;

    const position = {
      lat: parseFloat(this.props.storeHorizontalVal.lat),
      lng: parseFloat(this.props.storeHorizontalVal.lng)
    };

    if (this.state.map) {
      this.state.map.setCenter(position);
      this.state.map.setZoom(12);
    }

    // Use updateCurrentLocationMarker instead of separate clear and create
    this.updateCurrentLocationMarker(position);
  };

  clearCurrentLocationMarker = () => {
    if (this.state.currentLocationMarker) {
      this.state.currentLocationMarker.setMap(null);
      this.setState({ currentLocationMarker: null });
    }
  };

  clearAllMarkers = () => {
    // Clear current location marker
    if (this.state.currentLocationMarker) {
      this.state.currentLocationMarker.setMap(null);
    }

    // Clear all outlet markers
    if (Array.isArray(this.state.markers)) {
      this.state.markers.forEach(marker => marker.setMap(null));
    }

    this.setState({
      markers: [],
      currentLocationMarker: null
    });
  };

  resetAllMarkers = () => {
    return new Promise(resolve => {
      // Clear current location marker
      if (this.state.currentLocationMarker) {
        this.state.currentLocationMarker.setMap(null);
      }

      // Clear all outlet markers
      if (Array.isArray(this.state.markers)) {
        this.state.markers.forEach(marker => marker.setMap(null));
      }

      // Reset markers arrays in state - ensure we set currentLocationMarker to null
      this.setState(
        {
          markers: [],
          currentLocationMarker: null
        },
        () => {
          // Resolve after state is updated
          resolve();
        }
      );
    });
  };

  createNewMarker = position => {
    const { google } = window;
    const { map, theme } = this.state;

    if (!google || !map || !position.lat || !position.lng) return;

    const image = theme === 'delivery' ? Delivery : Restaurant;

    const marker = new google.maps.Marker({
      position: position,
      map: map,
      icon: {
        url: image,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 40)
      },
      draggable: false
    });

    this.setState({ currentLocationMarker: marker });
  };

  handleStoreHorizontalUpdate = () => {
    const isNearbyStores = SessionStorage.get(
      LocalSessionKey.NEARBY_BK_RESTUARANT
    );

    if (!isNearbyStores && this.props.storeHorizontalVal) {
      const position = {
        lat: parseFloat(this.props.storeHorizontalVal.lat),
        lng: parseFloat(this.props.storeHorizontalVal.lng)
      };
      this.updateMapCenter(position);
      this.updateCurrentLocationMarker(position);
    }
  };

  setInitialPosition = () => {
    const isNearbyStores = SessionStorage.get(
      LocalSessionKey.NEARBY_BK_RESTUARANT
    );

    if (!isNearbyStores && this.props.storeHorizontalVal) {
      const position = {
        lat: parseFloat(this.props.storeHorizontalVal.lat),
        lng: parseFloat(this.props.storeHorizontalVal.lng)
      };
      this.updateMapCenter(position);
      this.updateCurrentLocationMarker(position);
    } else if (isNearbyStores) {
      this.getCurrentLocation();
    }
  };

  getCurrentLocation = callback => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          LocalStorage.set('USER_LOCATION', userLocation);

          if (this.state.map) {
            this.updateMapCenter(userLocation);
            this.updateCurrentLocationMarker(userLocation);
            this.state.map.setZoom(12);
          }

          if (callback && typeof callback === 'function') {
            callback(userLocation);
          }
        },
        error => {
          console.error('Error getting location:', error);
          if (callback && typeof callback === 'function') {
            callback(this.state.latlng);
          }
        },
        { timeout: 15000, maximumAge: 600000 }
      );
    } else {
      if (callback && typeof callback === 'function') {
        callback(this.state.latlng);
      }
    }
  };

  handleLocationSelect = location => {
    if (location && location.lat && location.lng) {
      const position = {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng)
      };

      if (this.state.map) {
        this.state.map.setCenter(position);
      }

      LocalStorage.set('USER_LOCATION', position);

      this.updateCurrentLocationMarker(position);

      if (this.props.setLatLng) {
        this.props.setLatLng(position);
      }
    }
  };

  initializeMap = () => {
    const { google } = window;
    if (!google) return;

    const { theme, restaurantDetail, latlng } = this.state;

    const map = new google.maps.Map(document.getElementById('map'), {
      center: latlng,
      zoom: this.state.zoomLevel,
      disableDefaultUI: true,
      zoomControl: true,
      draggable: true,
      mapTypeId: 'roadmap'
    });

    this.setupMapListeners(map);

    this.setState(
      {
        map,
        isMapInitialized: true
      },
      () => {
        const isNearbyStores = SessionStorage.get(
          LocalSessionKey.NEARBY_BK_RESTUARANT
        );

        if (this.state.theme === 'delivery') {
          const deliveryAddress = JSON.parse(
            window.sessionStorage.getItem('DELIVERY_ADDRESS')
          );

          if (
            deliveryAddress &&
            deliveryAddress.latitude &&
            deliveryAddress.longitude
          ) {
            const deliveryPosition = {
              lat: parseFloat(deliveryAddress.latitude),
              lng: parseFloat(deliveryAddress.longitude)
            };
            this.updateMapCenter(deliveryPosition);
            this.updateCurrentLocationMarker(deliveryPosition);
          } else {
            // Fallback to current location if delivery address is not available
            this.getCurrentLocation(userLocation => {
              this.updateMapCenter(userLocation);
              this.updateCurrentLocationMarker(userLocation);
            });
          }
        } else if (!isNearbyStores && this.state.restaurantDetail) {
          const restaurantPosition = {
            lat: parseFloat(this.state.restaurantDetail.lat),
            lng: parseFloat(this.state.restaurantDetail.long)
          };
          this.updateMapCenter(restaurantPosition);
          this.updateCurrentLocationMarker(restaurantPosition);
        } else if (!isNearbyStores && this.props.storeHorizontalVal) {
          this.moveMarkerToStorePosition();
        } else {
          this.updateCurrentLocationMarker(latlng);
        }
      }
    );
  };
  updateMapCenter = location => {
    const { map } = this.state;

    if (!map || !location.lat || !location.lng) return;

    map.setCenter({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    });

    this.updateCurrentLocationMarker({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    });
  };

  updateCurrentLocationMarker = position => {
    const { google } = window;
    const { map, currentLocationMarker, theme } = this.state;

    if (!map || !position.lat || !position.lng) return;

    // Always remove the previous marker first
    if (currentLocationMarker) {
      currentLocationMarker.setMap(null);
    }

    // Use appropriate marker based on order type
    const markerIcon = theme === 'delivery' ? Delivery : Restaurant;

    const marker = new google.maps.Marker({
      position: position,
      map: map,
      icon: {
        url: markerIcon,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 40)
      },
      draggable: false
    });

    // Update state AFTER creating the new marker
    this.setState({ currentLocationMarker: marker });
  };

  updateMarkers = outletList => {
    const { google } = window;
    const { map, markers } = this.state;

    if (!google || !map) return;

    // Clear existing markers
    if (Array.isArray(markers)) {
      markers.forEach(marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
        }
        marker.setMap(null);
      });
    }

    let currentOpenInfoWindow = null;

    const newMarkers = outletList
      ?.filter(outlet => outlet.lat && outlet.long)
      .map(outlet => {
        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(outlet.lat),
            lng: parseFloat(outlet.long)
          },
          map,
          icon: {
            url: LOGO,
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 50),
            optimized: false
          },
          title: outlet.outlet_name || '',
          draggable: false
        });

        const infoWindowContent = `
        <div class="custom-info-window" style="
          padding: 8px;
          max-width: 250px;
          font-family: Arial, sans-serif;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          position: relative;
        ">
          <button class="info-window-close" style="
            position: absolute;
            top: 5px;
            right: 5px;
            border: none;
            background: none;
            cursor: pointer;
            padding: 5px;
            font-size: 16px;
            color: #666;
            line-height: 1;
          ">✕</button>
          <div style="
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 4px;
            color: #D62300;
            padding-right: 20px;
          ">${outlet.outlet_name || ''}</div>
          ${
            outlet.address
              ? `<div style="
              font-size: 12px;
              color: #333;
              line-height: 1.3;
              margin-bottom: 8px;
            ">${outlet.address}</div>`
              : ''
          }
          <div style="
            display: flex;
            flex-direction: column;
            gap: 4px;
          ">
            <a href="https://www.google.com/maps?q=${outlet.lat},${
          outlet.long
        }" 
               target="_blank" 
               style="
                text-decoration: none;
                color: white;
                background-color: #1a73e8;
                padding: 6px 10px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span style="margin-right: 4px;">🗺️</span>
              Open in Google Maps
            </a>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${
              outlet.lat
            },${outlet.long}" 
               target="_blank"
               style="
                text-decoration: none;
                color: #1a73e8;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 0;
              "
            >
              <span style="margin-right: 4px;">📍</span>
              Get Directions
            </a>
          </div>
        </div>
      `;

        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
          pixelOffset: new google.maps.Size(0, -25)
        });

        // Add click listener to marker
        marker.addListener('click', () => {
          if (currentOpenInfoWindow) {
            currentOpenInfoWindow.close();
          }
          infoWindow.open(map, marker);
          currentOpenInfoWindow = infoWindow;

          // Add event listener to close button after InfoWindow is opened
          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const closeButtons = document.getElementsByClassName(
              'info-window-close'
            );
            if (closeButtons && closeButtons.length > 0) {
              const closeButton = closeButtons[0];
              closeButton.addEventListener('click', () => {
                infoWindow.close();
                currentOpenInfoWindow = null;
              });
            }
          });
        });

        // Add close listener to info window
        google.maps.event.addListener(infoWindow, 'closeclick', () => {
          currentOpenInfoWindow = null;
        });

        marker.infoWindow = infoWindow;
        return marker;
      });

    this.setState({ markers: newMarkers || [] });
  };
  createTooltipContent = outlet => {
    const tooltipContent = document.createElement('div');
    tooltipContent.className = 'tooltip__container';
    tooltipContent.innerHTML = `
      <div class="tooltip__image__outlet">
        <div class="tooltip__image__outlet-details">
          <div class="tooltip__outlet-name">${outlet?.outlet_name || ''}</div>
          ${
            outlet?.address
              ? `<span class="tooltip__outlet-address">${outlet.address}</span>`
              : ''
          }
        </div>
      </div>
    `;
    return tooltipContent;
  };

  handleMarkerClick = outlet => {
    // Store the selected outlet detail in session storage
    window.sessionStorage.setItem('RESTAURANT_DETAIL', JSON.stringify(outlet));

    // Update state
    this.setState({ restaurantDetail: outlet });

    // Update UI
    const position = {
      lat: parseFloat(outlet.lat),
      lng: parseFloat(outlet.long)
    };

    this.updateMapCenter(position);

    // Perform any other actions needed when a marker is clicked
    if (this.props.onOutletSelect) {
      this.props.onOutletSelect(outlet);
    }
  };

  getOutletList = outletList => {
    let data = [];

    if (outletList && outletList.length) {
      data = outletList.map((outlet, index) => (
        <div key={index.toString()}>
          <div className="tooltip__image__outlet">
            <div className="tooltip__image__outlet-details">
              {outlet?.outlet_name}
              {outlet?.address && (
                <span className="tooltip__outlet-address">
                  {outlet?.address}
                </span>
              )}
            </div>
          </div>
        </div>
      ));
    }
    return data;
  };

  getMarkerIcon = outletList => {
    console.log('outletlist', outletList);

    const { google } = window;
    return (
      <>
        <ToolTip
          position="left"
          id="reference"
          content={this.getOutletList(outletList)}
        />
        {{
          url: LOGO,
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 40)
        }}
      </>
    );
  };

  setupMapListeners = map => {
    map.addListener('zoom_changed', () => {
      const newZoom = map.getZoom();
      this.setState({ zoomLevel: newZoom });
      LocalStorage.set('mapZoomLevel', newZoom);
    });
  };

  setupZoomControls = map => {
    const { google } = window;

    const zoomInButton = document.createElement('button');
    zoomInButton.textContent = '+';
    zoomInButton.classList.add('zoom-button', 'zoom-in');
    zoomInButton.addEventListener('click', () =>
      map.setZoom(map.getZoom() + 1)
    );

    const zoomOutButton = document.createElement('button');
    zoomOutButton.textContent = '-';
    zoomOutButton.classList.add('zoom-button', 'zoom-out');
    zoomOutButton.addEventListener('click', () =>
      map.setZoom(map.getZoom() - 1)
    );

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomOutButton);
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomInButton);
  };

  // handleNearbyStores = () => {
  //   const { restaurantDetail } = this.state;
  //   if (!restaurantDetail) return;

  //   console.log('nearby store is called');
  //   this.getCurrentLocation(userLocation => {
  //     this.clearCurrentLocationMarker();

  //     if (this.state.map) {
  //       this.state.map.setCenter(userLocation);
  //       this.state.map.setZoom(15);
  //     }

  //     this.createNewMarker(userLocation);

  //     const payload = {
  //       lat: restaurantDetail.lat,
  //       long: restaurantDetail.long
  //     };

  //     SessionStorage.set(LocalSessionKey.NEARBY_BK_RESTUARANT, true);
  //     console.log('Nearby stores clicked');
  //     this.props.getOutletData(payload);
  //   });
  // };

  completeMapReset = () => {
    return new Promise(resolve => {
      console.log('Starting complete map reset');

      // 1. Clear all outlet markers one by one
      if (Array.isArray(this.state.markers)) {
        this.state.markers.forEach(marker => {
          // Close any open infoWindows for this marker
          if (marker.infoWindow) {
            marker.infoWindow.close();
          }
          // Remove the marker from the map
          marker.setMap(null);
        });
      }

      // 2. Clear the current location marker separately
      if (this.state.currentLocationMarker) {
        this.state.currentLocationMarker.setMap(null);
      }

      // 3. Clear internal references to ensure garbage collection
      const clearedState = {
        markers: [],
        currentLocationMarker: null
      };

      // 4. Force update state and ensure it completes before continuing
      this.setState(clearedState, () => {
        console.log('Map reset complete, all markers cleared');
        // Give a small delay for any pending Google Maps operations
        setTimeout(() => {
          resolve();
        }, 50);
      });
    });
  };

  getNavigation = (address, lat, lng) => {
    const newAddress = splitAddress(address);
    if (newAddress) {
      window.open(`http://maps.google.com/maps?q=${newAddress}`, '_blank');
    } else {
      window.open(`http://maps.google.com/maps?q=${lat},${lng}`, '_blank');
    }
  };

  // Replace handleNearbyStores with this completely new implementation
  handleNearbyStores = async () => {
    console.log('Fetching nearby stores');

    try {
      // First ensure all markers are completely cleared
      await this.completeMapReset();

      // Get current user location with a promise-based approach
      const getUserLocation = () => {
        return new Promise((resolve, reject) => {
          if (!navigator.geolocation) {
            reject(new Error('Geolocation not supported'));
            return;
          }

          navigator.geolocation.getCurrentPosition(
            position => {
              const userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              resolve(userLocation);
            },
            error => {
              console.error('Error getting location:', error);
              reject(error);
            },
            { timeout: 15000, maximumAge: 600000 }
          );
        });
      };

      // Get location
      const userLocation = await getUserLocation();

      // Store in storage
      LocalStorage.set('USER_LOCATION', userLocation);
      SessionStorage.set(LocalSessionKey.NEARBY_BK_RESTUARANT, true);

      // Update map center
      if (this.state.map) {
        this.state.map.setCenter(userLocation);
        this.state.map.setZoom(12);
      }

      // Now create a new marker for the current location
      const { google } = window;
      if (google && this.state.map) {
        const markerIcon =
          this.state.theme === 'delivery' ? Delivery : Restaurant;

        // Create marker but don't add it to state yet
        const newMarker = new google.maps.Marker({
          position: userLocation,
          map: this.state.map,
          icon: {
            url: markerIcon,
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 40)
          },
          draggable: false
        });

        // Update state with new marker in a promise
        await new Promise(resolve => {
          this.setState({ currentLocationMarker: newMarker }, resolve);
        });
      }

      // Finally, fetch nearby outlets
      const payload = {
        lat: userLocation.lat,
        long: userLocation.lng
      };

      // Fetch outlet data after everything else is done
      this.props.getOutletData(payload);
    } catch (error) {
      console.error('Error in handleNearbyStores:', error);
    }
  };

  render() {
    const textColor =
      SessionStorage.get(LocalSessionKey.ORDER_TYPE) === 'delivery'
        ? '#EE7000'
        : '#6F3C2F';

    return (
      <div className="google-map-container">
        <div
          id="map"
          className="map"
          style={{
            border: `2.5px solid ${
              SessionStorage.get(LocalSessionKey.ORDER_TYPE) === 'delivery'
                ? '#ee7700'
                : '#6f3c2f'
            }`
          }}
        ></div>
        <button
          className="nearby-stores-button"
          onClick={this.handleNearbyStores}
          style={{ color: textColor }}
        >
          <img
            src={
              SessionStorage.get(LocalSessionKey.ORDER_TYPE) === 'delivery'
                ? LocationDelivery
                : LocationDinein
            }
            alt="location"
            style={{
              width: '20px',
              height: '20px',
              marginRight: '8px',
              verticalAlign: 'middle'
            }}
          />
          BK Restaurants Near You
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  outletList: state?.address?.outlets?.content
});

const mapDispatchToProps = dispatch => ({
  getOutletData: payload => dispatch(getOutlet(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapSearch);
